<template>
  <div class="grid">
    <div class="col-12 md:col-12">
      <Toast/>
      <h3>{{ $t('deliveryNote.title') }}</h3>
      <Panel toggleable class="col-12">
        <template #header>
         <div class="flex align-items-center gap-2">
            <Button :label="$t('button.save')" icon="pi pi-save" class="p-button mr-2" @click="onSave" />
            <div style="font-weight: bold; font-size: 1.2rem;s"></div>     
        </div>
        </template>
        <div class="grid col=12">
          <div class="md:col-6">
            <div class="card p-fluid">
              <div class="grid field" style="margin-top: 0px;">
                <label for="deliveryAgent" class="md:col-3" style="font-weight: bold;">{{$t('deliveryNote.deliveryAgent')}}</label>
                <form-field :message="errors.deliveryAgent" class="md:col-9">
                  <InputText ref="deliveryAgent" id="deliveryAgent" type="text" v-model="entity.deliveryAgent"/>
                  <!-- <AutoCompleteValue ref="deliveryAgent" id="deliveryAgent" v-model="entity.deliveryAgent"
                    :options="deliveryAgents" :required="true" placeholder="" @item-select="onChangeAgent($event)" /> -->
                </form-field>
                <label for="courierName" class="md:col-3" style="font-weight: bold;">{{$t('deliveryNote.courierName')}}</label>
                <form-field :message="errors.courierName" class="md:col-9">
                  <InputText ref="courierName" id="courierName" type="text" v-model="entity.courierName"/>
                </form-field>
                <label for="courierPhone" class="md:col-3" style="font-weight: bold;">{{$t('deliveryNote.courierPhone')}}</label>
                <form-field :message="errors.courierPhone" class="md:col-9">
                  <InputText ref="courierPhone" id="courierPhone" type="text" v-model="entity.courierPhone"/>
                </form-field>
              </div>
            </div>
          </div>
          <div class="md:col-6">
            <div class="card p-fluid">
              <!-- <div class="grid" style="margin-top: 0px">
                <label class="md:col-3" style="font-weight: bold;">{{$t('shipment.weightPartnerLbs')}}</label>
                <div class="md:col-3" style="font-weight: bold">{{ shipment.weightPartnerLbs || 0 }} {{ $t('unit.lbs') }} / {{shipment.weightPartnerKg || 0}} {{$t('unit.kg')}}</div>
              </div>
              <div class="grid" style="margin-top: 0px">
                <label class="md:col-3" style="font-weight: bold;">{{$t('shipment.weightActual')}}</label>
                <div class="md:col-3" style="font-weight: bold">{{ shipment.weightActualLbs || 0 }} {{$t('unit.lbs')}} / {{shipment.weightActualKg || 0}} {{$t('unit.kg')}}</div>
              </div> -->
              <div class="grid" style="margin-top: 0px">
                <label for="recipient" class="md:col-3" style="font-weight: bold;">{{$t('deliveryNote.recipient')}}</label>
                <form-field :message="errors.recipient" class="md:col-9">
                  <InputText ref="recipient" id="recipient" type="text" v-model="entity.recipient"/>
                </form-field>
              </div>
              <div class="grid" style="margin-top: 0px">
                <label for="packageNumber" class="md:col-3" style="font-weight: bold;">{{$t('deliveryNote.packageNumber')}}</label>
                <form-field :message="errors.packageNumber" class="md:col-9">
                  <InputNumber ref="packageNumber" id="packageNumber" type="text" v-model="entity.packageNumber"/>
                </form-field>
              </div>
              <div class="grid" style="margin-top: 0px">
                <label for="note" class="md:col-3" style="font-weight: bold;">{{$t('deliveryNote.note')}}</label>
                <form-field :message="errors.note" class="md:col-9">
                  <Textarea ref="note" id="note" type="text" v-model="entity.note"/>
                </form-field>
              </div>
            </div> 
          </div>
        </div>
      </Panel>

      <!-- <Toolbar class="mb-4">
        <template v-slot:start>
          <Button :label="$t('button.save')" icon="pi pi-save" class="p-button mr-2" @click="onSave" />
          <Button :label="$t('button.print')" icon="pi pi-print" class="p-button mr-2" @click="onPreviewShipping" v-if="shipment.id" />
          <Button :label="$t('button.info')" icon="pi pi-book" class="p-button mr-2" @click="onEditInfo" /> 
          <Button :label="$t('button.scanner')" icon="pi pi-search" class="p-button mr-2" @click="openScanner" v-if="shipment.id"/>
          <Button :label="$t('button.export')" icon="pi pi-file-export" class="p-button" @click="onExportManifest" v-if="shipment.id"/>
        </template>
        <template #center>
          <div class="my-2">
          </div>             
        </template>
        <template v-slot:end>  
          <Button :label="$t('button.qrcode')" icon="pi pi-qrcode" class="p-button mr-2" @click="showShipmentURL" v-if="shipment.id"/>
          <Button label="Export" icon="pi pi-upload" class="p-button-success" @click="exportCSV($event)"  />
          <Button :label="$t('button.delete')" icon="pi pi-trash" class="p-button-danger" @click="onDelete" />
        </template>
      </Toolbar> -->
        
      <div class="md:col-12">
        <DataTable ref="source" style="width: 100%;"
            dataKey="id" 
            filterDisplay="menu"
            v-model:editingRows="editingRows"
            v-model:selection="selectedReceipts"
            :value="receipts"  
            :showGridlines=true                
            v-model:filters="filters"
            :paginator="true" 
            :totalRecords="totalReceipts"
            :rows="pageSize"
            paginatorTemplate='CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown'
            :rowsPerPageOptions="rowsPerPageOptions"
            currentPageReportTemplate='Showing {first} to {last} of {totalRecords} packages'               
            responsiveLayout="scroll"
            :lazy="true"
            @filter="onFilter">
          <template #header>
            <div class="flex justify-content-between">
              <div class="flex align-items-center gap-2">
                <Button type="button" icon="pi pi-filter-slash" label="Clear" @click="clearFilter()" />
                <Button type="button" icon="pi pi-store" :label="$t('button.store')" @click="storePackages()" />
              </div>
                
              <IconField iconPosition="left">
                  <InputIcon>
                      <i class="pi pi-search" />
                  </InputIcon>
                  <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
              </IconField>
            </div>
          </template>  
          <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
          <Column :rowEditor="true" style="width: 5%; min-width: 8rem" bodyStyle="text-align:center"></Column>
          <Column field="createdDate" filterField="createdDate"
            :header="$t('package.createdDate')" 
            :sortable="true" 
            dataType="date"
            headerStyle="width:8%; min-width:8rem;">
            <template #body="slotProps">
              <span class="p-column-title">{{$t('package.createdDate')}}</span>
              {{this.formatDate(slotProps.data.createdDate)}}
            </template>
            <template #filter="{filterModel}">
              <Calendar v-model="filterModel.value" dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" />
            </template>
          </Column>
          <Column field="referenceCode" 
            :header="$t('package.referenceCode')" 
            :sortable="true" 
            headerStyle="width:10%; min-width:10rem;">
            <template #body="slotProps">
              <span class="p-column-title">{{$t('package.referenceCode')}}</span>
              {{slotProps.data.referenceCode}}
            </template>
            <template #filter="{filterModel}">
              <InputText 
                v-model="filterModel.value" 
                class="p-column-filter" 
                :placeholder="$t('common.search_by_field',[$t('package.referenceCode')])"/>
            </template>
          </Column>
          <Column field="packageCode" 
            :header="$t('package.packageCode')" 
            :sortable="true" 
            headerStyle="width:10%; min-width:10rem;">
            <template #body="slotProps">
              <span class="p-column-title">{{$t('package.packageCode')}}</span>
              {{slotProps.data.packageCode}}
            </template>
            <template #filter="{filterModel}">
              <InputText 
                v-model="filterModel.value" 
                class="p-column-filter" 
                :placeholder="$t('common.search_by_field',[$t('package.packageCode')])"/>
            </template>
          </Column>
          <Column field="weightPartnerLbs" :header="$t('package.weightPartnerLbs') + ' ' + $t('unit.kgperlbs')" :sortable="true" headerStyle="width:13%; min-width:12rem;">
            <template #body="slotProps">
              <span class="p-column-title">{{$t('package.weightPartnerLbs')}}</span>
              {{tokg(slotProps.data.weightPartnerLbs)}} / {{slotProps.data.weightPartnerLbs || 0}}
            </template>
            <template #filter="{filterModel}">                  
              <InputNumber v-model="filterModel.value" class="p-column-filter" :placeholder="$t('common.search_by_field',[$t('package.weightLbs')])"/>              
            </template>
          </Column>
          <Column field="weightActualKg" :header="$t('package.weightActualKg') + ' ' + $t('unit.kgperlbs')" :sortable="true" headerStyle="width:12%; min-width:10rem;">
            <template #body="slotProps">
              <span class="p-column-title">{{$t('package.weightActualKg')}}</span>
              {{slotProps.data.weightActualKg}} / {{tolbs(slotProps.data.weightActualKg)}}
            </template>
            <template #editor="{ data, field }">
              <InputNumber v-model="data[field]" :placeholder="$t('package.weightActualKg')"/>
            </template>
            <template #filter="{filterModel}">                  
              <InputNumber v-model="filterModel.value" class="p-column-filter" :placeholder="$t('common.search_by_field',[$t('package.weightLbs')])"/>              
            </template>
          </Column>
          <Column field="goodsType" :header="$t('package.goodsType')" :sortable="true" headerStyle="width:10%; min-width:8rem;">
            <template #body="slotProps">
              <span class="p-column-title">{{$t('package.goodsType')}}</span>
              {{$t("package.goodsTypes." + (slotProps.data.goodsType || "normal"))}}
            </template>
            <template #editor="{ data, field }">
              <Dropdown v-model="data[field]" :options="goodsTypes" optionLabel="label" optionValue="value" style="width: 100%">
                <template #option="slotProps">
                  <span>{{slotProps.option.label}}</span>
                </template>
              </Dropdown>
            </template>
            <template #filter="{filterModel}">                  
              <Dropdown v-model="filterModel.value" :options="goodsTypes" optionLabel="label" optionValue="value">
                <template #option="slotProps">
                  <span>{{slotProps.option.label}}</span>
                </template>
              </Dropdown>
            </template>
          </Column>
          <Column field="processStatus" :header="$t('package.processStatus')" :sortable="true" headerStyle="width:10%; min-width:8rem;">
            <template #body="slotProps">
              <span class="p-column-title">{{$t('package.processStatus')}}</span>
              {{$t("package.processStatuses." + slotProps.data.processStatus)}}
            </template>
            <template #editor="{ data, field }">
              <Dropdown v-model="data[field]" :options="processStatuses" optionLabel="label" optionValue="value" style="width: 100%">
                <template #option="slotProps">
                  <span>{{slotProps.option.label}}</span>
                </template>
              </Dropdown>
            </template>
            <template #filter="{filterModel}">                  
              <Dropdown v-model="filterModel.value" :options="processStatus" optionLabel="label" optionValue="value">
                <template #option="slotProps">
                  <span>{{slotProps.option.label}}</span>
                </template>
              </Dropdown>
            </template>
          </Column>
          <Column field="appearance" :header="$t('package.appearance')" :sortable="true" headerStyle="width:10%; min-width:8rem;">
            <template #body="slotProps">
              <span class="p-column-title">{{$t('package.appearance')}}</span>
              {{$t("package.appearances." + (slotProps.data.appearance || "normal"))}}
            </template>
            <template #editor="{ data, field }">
              <Dropdown v-model="data[field]" :options="appearanceOptions" optionLabel="label" optionValue="value" style="width: 100%">
                <template #option="slotProps">
                  <span>{{slotProps.option.label}}</span>
                </template>
              </Dropdown>
            </template>
            <template #filter="{filterModel}">                  
              <Dropdown v-model="filterModel.value" :options="appearanceOptions" optionLabel="label" optionValue="value">
                <template #option="slotProps">
                  <span>{{slotProps.option.label}}</span>
                </template>
              </Dropdown>
            </template>
          </Column>
          <Column field="storeNote" :header="$t('package.storeNote')" :sortable="true" headerStyle="min-width:10rem;">
            <template #body="slotProps">
              <span class="p-column-title">{{$t('package.storeNote')}}</span>
              {{slotProps.data.storeNote}}
            </template>
            <template #editor="{ data, field }">
              <Textarea v-model="data[field]" style="width: 100%" rows="1" autoResize/>
            </template>
            <template #filter="{filterModel}">                  
              <InputText 
                v-model="filterModel.value" 
                class="p-column-filter" 
                :placeholder="$t('common.search_by_field',[$t('package.packageCode')])"/>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>    
    
    <Dialog v-model:visible="showProgressSpinner" modal :closable="false" :style="{ width: '50vw' }">
      <ProgressSpinner/>
    </Dialog>
    <!-- <DialogExportForm :entity="shipment" :visible="showDialogInfo"
					@hide="showDialogInfo=false"
					@cancel="showDialogInfo=false"
					@save="saveEntityInfo($event)"/>
    <DialogExportQRCode :shipmentId="shipment.id" v-model:visible="showDialogQRCode"
					@hide="showDialogQRCode=false"
					@cancel="showDialogQRCode=false"/> -->
  </div>
</template>
<script>
import { FilterService, FilterOperator, FilterMatchMode } from "primevue/api";
import FormField from "@/components/form/FormField";
import DialogExportForm from "./DialogExportForm.vue";
import DialogExportQRCode from "./DialogExportQRCode.vue";
// import PickList from "@/components/PickList";
// import PackageItem from "@/components/PackageItem";
// import AutoCompleteValue from "@/components/form/AutoCompleteValue";
import ShipmentService from "@/service/ShipmentService";
import ReceiptService from "@/service/ReceiptService";
import formatter from "@/utils/formatter";
import adjustment from "@/utils/adjustment";
import { converter, getCodeString } from "@/utils/common";
import { Consts } from '@/utils/consts';
import { writeFile, utils } from "xlsx";
import filter from '@/mixins/filter'
export default {
  components: { DialogExportForm, DialogExportQRCode, FormField },
  mixins: [filter],
  data() {
    return {
      errors: {},
      entity: {},
      receipts: [],
      editingRows: [],
      selectedReceipts: [],
      showDialogInfo: false,
      showDialogQRCode: false,
      showProgressSpinner:false,
      selectedPackages: [],
      totalReceipts: 0,
      filterOptions: {
        page: 0,
        size: Consts.DEFAULT_PAGE_ROWS,
      },
      filters: {
        global: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        packageCode: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
      },
      filterOps: [
        { name: "Contains" },
        { name: "Greater than" },
        { name: "Less than" },
        { name: "Between" },
      ],
      pageSize: Consts.DEFAULT_PAGE_ROWS,
      rowsPerPageOptions: Consts.ROW_PER_PAGE_OPTIONS,
      currentSourcePage: 0,
      currentSourceRows: Consts.DEFAULT_PAGE_ROWS,
      formatter,
      enableTimeout: true,
      timeout: 5000,
      timeoutUnshippedPackages: null,
    };
  },
  computed: {
  },
  mounted() {
    this.loadUndeliveredReceipts();
  },

  methods: {
    async loadUndeliveredReceipts() {
      const response = await ReceiptService.getUndeliveredReceipts(this.filterOptions);
      this.receipts = response;
    },
    tolbs(kg) {
      return converter.tolbs(kg);
    },
    tokg(lbs) {
      return converter.tokg(lbs);
    },
    formatDate(jsDate) {
			return formatter.formatDate(jsDate, Consts.FORMAT_DATE_US);
		},
    async onSave() {
      // this.shipment.packageIds = this.allTargetPackages.map((pkg) => pkg.id);
      // if (this.shipment.id) {
      //   this.showProgressSpinner = true;
      //   ShipmentService.update(this.shipment.id, this.shipment).then(
      //     (response) => {
      //       this.shipment = adjustment.adjustShipment(response);
      //       this.allSourcePackages.forEach(item=>{item.shipmentCode = null;});
      //       this.allTargetPackages.forEach(item=>{item.shipmentCode = this.shipment.shipmentCode;});
      //       this.showProgressSpinner = false;
      //       this.$toast.add({severity:'success', summary: 'Successful', detail: this.$t("shipment.update_packages_successfully"), life: 3000});
      //     }
      //   ).catch((e) => {
      //       console.log(e);
      //       this.showProgressSpinner = false;
      //     });
      // } else {
      //   this.showProgressSpinner = true;
      //   ShipmentService.create(this.shipment)
      //     .then((response) => {
      //       this.shipment = adjustment.adjustShipment(response);
      //       this.showProgressSpinner = false;
      //     })
      //     .catch((e) => {
      //       console.log(e);
      //       this.showProgressSpinner = false;
      //     });
      // }
    },
    onFilter(e) {
      console.log(e);
      this.filterOptions.page = 0;
      this.loadUndeliveredReceipts();
    },
    onPreviewShipping() {
      const routeData = this.$router.resolve({ name: 'print_preview', params: {resource: "shipment", id: this.shipment.id} });
      window.open(routeData.href);
    },
    onEditInfo() {
      this.showDialogInfo = true;
    },
    openScanner() {
      const routeData = this.$router.resolve({ name: 'agent_package_scanner', params: {id: this.shipment.id} });
      window.open(routeData.href);
    },
    showShipmentURL() {
      this.showDialogQRCode = true;
    },
    saveEntityInfo(info) {
      for (let field in info){
        this.shipment[field] = info[field];
      }
      if (this.shipment.id) {
        let data = {
          id: this.shipment.id
        };
        if (this.shipment.status == "inited") {
          ["note","departure","awbCode","weightLbs"].forEach(field => {
            data[field] = this.shipment[field];
          });
        } else {
          data.note = this.shipment.note;
        }
        ShipmentService.update(this.shipment.id, data).then(
          (response) => {
            this.shipment = adjustment.adjustShipment(response);
            this.showDialogInfo = false;
          }
        );
      } else {
        ShipmentService.create(this.shipment)
          .then((response) => {
            this.shipment = adjustment.adjustShipment(response);
            this.showDialogInfo = false;
          })
          .catch((e) => console.log(e));
      }
      
    },
    _doFilter(aPackageList, filter) {
      let filteredPackages = [];
      if (filter.receiptCode) {
        filteredPackages = FilterService.filter(
          aPackageList,
          ["receiptCode"],
          filter.receiptCode,
          FilterMatchMode.EQUALS
        );
        let start, end;
        if (filter.first) {
          start = getCodeString(filter.first, 3, filter.receiptCode);
        }
        if (filter.last) {
          end = getCodeString(filter.last, 3, filter.receiptCode);
        }
        if (start && end) {
          filteredPackages = FilterService.filter(
            filteredPackages,
            ["packageCode"],
            [start, end],
            FilterMatchMode.BETWEEN
          );
        } else if (start) {
          filteredPackages = FilterService.filter(
            filteredPackages,
            ["packageCode"],
            start,
            FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
          );
        } else if (end) {
          filteredPackages = FilterService.filter(
            filteredPackages,
            ["packageCode"],
            end,
            FilterMatchMode.LESS_THAN_OR_EQUAL_TO
          );
        }
      } else {
        filteredPackages = aPackageList;
      }
      return filteredPackages;
    },
    onExportManifest() {
      let receipts = {};
      let selectedPackages = this.allPackages[1];
      selectedPackages = selectedPackages.sort(
        (a, b) => a.packageCode < b.packageCode
      );
      this.shipment.receipts.forEach((receipt) => {
        receipts[receipt.receiptCode] = receipt;
      });
      /*
      selectedPackages.forEach(pkg => {
        const receipt = this._addPackage(receipts[pkg.receiptCode], pkg)
        if (receipt) {
          receipts[pkg.receiptCode] = receipt
        }
      });
      */
      //Manifests
      const manifests = this._createManifests(selectedPackages, receipts);
      // Shipping form
      const shipingForm = this._createShippingForm(selectedPackages, receipts);

      var wb = utils.book_new(); // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      utils.book_append_sheet(wb, manifests, "Manifests"); // sheetAName is name of Worksheet
      utils.book_append_sheet(wb, shipingForm, "ShippingList"); // sheetAName is name of Worksheet
      // export Excel file
      const fileName = "shipment-" + this.shipment.shipmentCode + ".xlsx";
      writeFile(wb, fileName); // name of the file is 'book.xlsx'
    },
    _createManifests(selectedPackages, mapReceipts) {
      const manifests = selectedPackages.map((pkg, ind) => {
        const receipt = mapReceipts[pkg.receiptCode];
        const hdrPackageCode = this.$t("package.packageCode");
        const hdrWeightLbs = this.$t("shipment.weightLbs");
        const hdrWeightKg = this.$t("shipment.weightKg");
        const hdrSenderName = this.$t("shipment.senderName");
        const hdrSenderAddress = this.$t("shipment.senderAddress");
        const hdrSenderPhone = this.$t("shipment.senderPhone");
        const hdrRecipientName = this.$t("shipment.recipientName");
        const hdrRecipientAddress = this.$t("shipment.recipientAddress");
        const hdrRecipientPhone = this.$t("shipment.recipientPhone");
        const hdrDescription = this.$t("shipment.description");
        return {
          No: ind + 1,
          [hdrPackageCode]: pkg.packageCode,
          ["Pcs"]: 1,
          [hdrWeightLbs]: receipt.weightLbs,
          [hdrWeightKg]: converter.tokg(receipt.weightLbs),
          [hdrSenderName]: receipt.dSenderName || receipt.senderName,
          [hdrSenderAddress]: receipt.dSenderAddress || receipt.senderAddress,
          [hdrSenderPhone]: receipt.dSenderPhone || receipt.senderPhone,
          [hdrRecipientName]: receipt.dRecipientName || receipt.recipientName,
          [hdrRecipientAddress]:
            receipt.dRecipientAddress || receipt.recipientAddress,
          [hdrRecipientPhone]:
            receipt.dRecipientPhone || receipt.recipientPhone,
          
          ["Value"]: "GIFT",
          [hdrDescription]: pkg.packageItems
            .map((item) => item.quantity + " " + item.name)
            .join(", "),
        };
      });
      var sheet = utils.json_to_sheet(manifests, {
        origin: "A4",
      });
      sheet["!cols"] = [
        { wch: 6 },
        { wch: 12 },
        { wch: 18 },
        { wch: 30 },
        { wch: 10 },
        { wch: 18 },
        { wch: 30 },
        { wch: 10 },
        { wch: 6 },
        { wch: 6 },
        { wch: 6 },
        { wch: 30 },
      ];

      const totalWeight = selectedPackages.reduce(
        (total, pkg) => total + pkg.weightLbs,
        0
      );
      utils.sheet_add_aoa(sheet, [[this.shipment.shipmentCode]], {
        origin: "A1",
      });
      utils.sheet_add_aoa(sheet, [[this.shipment.awbCode]], {
        origin: "C1",
      });
      utils.sheet_add_aoa(
        sheet,
        [
          [
            this.$t("shipment.number_of_packages", {
              numPackages: selectedPackages.length,
            }),
          ],
        ],
        { origin: "A2" }
      );
      utils.sheet_add_aoa(
        sheet,
        [[this.$t("shipment.totalWeight", { weight: totalWeight })]],
        { origin: "D2" }
      );
      return sheet;
    },
    _createShippingForm(selectedPackages, mapReceipts) {
      const hdrIndex = this.$t("package.noOfPCs");
      const hdrReceiptNumber = this.$t("shipment.receiptNumber");
      const hdrPackingNumber = this.$t("shipment.packingNumber");
      const hdrShipmentNumber = this.$t("shipment.shipmentNumber");
      const hdrWeightLbs = this.$t("shipment.weightLbs");
      const hdrWeightKg = this.$t("shipment.weightKg");
      const hdrRecipientName = this.$t("shipment.recipientName");
      const hdrRecipientAddress = this.$t("shipment.recipientAddress");
      //const hdrRecipientDistrict = this.$t('shipment.recipientDistrict');
      //const hdrRecipientCity = this.$t('shipment.recipientCity');
      const hdrRecipientPhone = this.$t("shipment.recipientPhone");
      const hdrDescription = this.$t("shipment.description");
      const shippingForm = selectedPackages.map((pkg, ind) => {
        const receipt = mapReceipts[pkg.receiptCode];
        return {
          [hdrIndex]: ind + 1,
          [hdrReceiptNumber]: pkg.receiptCode,
          [hdrPackingNumber]: pkg.packageCode,
          [hdrShipmentNumber]: this.shipment.shipmentCode,
          [hdrWeightLbs]: pkg.weightLbs,
          [hdrWeightKg]: converter.tokg(pkg.weightLbs),
          [hdrRecipientName]: receipt.dRecipientName || receipt.recipientName,
          [hdrRecipientAddress]:
            receipt.dRecipientAddress ||
            [
              receipt.recipientAddress,
              receipt.receiptDistrict,
              receipt.receiptProvince,
            ].join(","),
          [hdrRecipientPhone]:
            receipt.dRecipientPhone || receipt.recipientPhone,
          [hdrDescription]: pkg.packageItems
            .map((item) => item.quantity + " " + item.name)
            .join(","),
        };
      });
      var sheet = utils.json_to_sheet(shippingForm, {
        origin: "A4",
      });
      sheet["!cols"] = [
        { wch: 6 },
        { wch: 10 },
        { wch: 12 },
        { wch: 8 },
        { wch: 6 },
        { wch: 6 },
        { wch: 18 },
        { wch: 30 },
        { wch: 10 },
        { wch: 30 },
      ];
      const totalWeight = selectedPackages.reduce(
        (total, pkg) => total + pkg.weightLbs,
        0
      );
      utils.sheet_add_aoa(sheet, [[this.$t("shipment.shippingFormTitle")]], {
        origin: "A1",
      });
      utils.sheet_add_aoa(
        sheet,
        [
          [
            this.$t("shipment.number_of_packages", {
              numPackages: selectedPackages.length,
            }),
          ],
        ],
        { origin: "A2" }
      );
      utils.sheet_add_aoa(
        sheet,
        [[this.$t("shipment.totalWeight", { weight: totalWeight })]],
        { origin: "D2" }
      );
      return sheet;
    },
    _addPackage(receipt, pkg) {
      if (!receipt && this.shipment && this.shipment.receipts) {
        for (let i = 0; i < this.shipment.receipts.length; i++) {
          if (this.shipment.receipts[i].receiptCode == pkg.receiptCode) {
            receipt = {
              ...this.shipment.receipts[i],
              packageNumber: 0,
              weightLbs: 0,
              content: "",
            };
            break;
          }
        }
      }
      if (receipt) {
        receipt.packageNumber += 1;
        receipt.weightLbs += pkg.weightLbs;
        if (!receipt.content) {
          receipt.content = this._getPackageContent(pkg);
        } else {
          receipt.content += "," + this._getPackageContent(pkg);
        }
      }
      return receipt;
    },
    //Todo:
    _getPackageContent(pkg) {
      return pkg.description;
    },
  },
};
</script>

<style lang="scss" scoped>
/*
.p-picklist-header .p-toolbar {
  padding: 0 1rem;
  margin: -0.5rem 0;
  border: 0;
  font-weight: 600;
}
*/
</style>
